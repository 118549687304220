<template>
  <div class="row g-0">
    <div class="col-lg-6 mx-auto">
      <div class="card">
        <div class="card-body">
          <div class="mb-2">
            <label class="form-label">{{
              $t("directory_update.account_id")
            }}</label>
            <input
              v-model="state.account"
              type="text"
              class="form-control font-monospace"
              :class="{ 'border-danger': v.account.$errors.length }"
              placeholder="GD7AO5CGO7TIPNY4VTSGIAZQF4EFK3YVGRRRYBERIBM2T2I4LTH7PMFP"
            />
            <div v-if="apiError" class="form-text text-danger">
              {{ apiError }}
            </div>
            <div
              v-for="error of v.account.$errors"
              :key="error.$uid"
              class="form-text text-danger"
            >
              {{ error.$message }}
            </div>
            <div class="form-text">
              {{ $t("directory_update.account_id_label") }}
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label"
              >{{ $t("directory_update.label") }}
            </label>
            <input
              v-model="state.labelName"
              type="text"
              class="form-control"
              placeholder="Test"
              :class="{ 'border-danger': v.account.$errors.length }"
            />
            <div
              v-for="error of v.labelName.$errors"
              :key="error.$uid"
              class="form-text text-danger"
            >
              {{ error.$message }}
            </div>
            <div class="form-text">
              {{ $t("directory_update.label_added_to_account") }}
            </div>
          </div>
          <div class="mb-2">
            <label class="form-label"
              >{{ $t("directory_update.email") }}
            </label>
            <input
              v-model="state.email"
              type="text"
              class="form-control"
              placeholder="test@stellarchain.io"
              :class="{ 'border-danger': v.account.$errors.length }"
            />
            <div
              v-for="error of v.email.$errors"
              :key="error.$uid"
              class="form-text text-danger"
            >
              {{ error.$message }}
            </div>
          </div>
          <label class="form-label">{{
            $t("directory_update.verified_type")
          }}</label>
          <div class="row g-2 mb-2" role="tablist">
            <label class="col-lg-12 active">
              <input
                type="radio"
                name="verified"
                class="card-input-element d-none"
                value="1"
                checked=""
              />
              <div
                class="card w-100 border-1"
                @click="changeType('user_defined')"
              >
                <div class="card-body text-center d-flex py-2">
                  <span
                    class="
                      flex-shrink-0
                      bg-white
                      rounded-3
                      d-flex
                      align-self-center
                    "
                    style="height: 36px; width: 36px"
                  >
                    <i
                      class="
                        bi bi-person-circle
                        fs-5
                        align-self-center
                        mx-auto
                        text-primary
                      "
                    ></i>
                  </span>
                  <div class="flex-shrink-0 ms-3 text-start">
                    <span
                      class="
                        fw-bold
                        text-decoration-none 
                        font-monospace
                        small
                      "
                    >
                      {{ $t("directory_update.user_defined") }}
                    </span>
                    <p class="small mb-0 font-monospace text-muted">
                      {{ $t("directory_update.for_normal_users") }}
                    </p>
                  </div>
                  <span
                    class="
                      ms-auto
                      me-4
                      bg-white
                      border
                      rounded-3
                      d-flex
                      align-self-center
                    "
                    style="height: 36px; width: 52px"
                  >
                    <span class="text-primary align-self-center mx-auto"
                      >$99</span
                    >
                  </span>
                </div>
              </div>
            </label>
            <label class="col-lg-12">
              <input
                type="radio"
                name="verified"
                class="card-input-element d-none"
                value="2"
              />
              <div class="card w-100 border-1" @click="changeType('verified')">
                <div class="card-body text-center d-flex py-2">
                  <span
                    class="
                      flex-shrink-0
                      bg-primary bg-opacity-25
                      rounded-3
                      d-flex
                      align-self-center
                    "
                    style="height: 36px; width: 36px"
                  >
                    <i
                      class="
                        bi bi-patch-check-fill
                        fs-5
                        align-self-center
                        mx-auto
                        text-primary
                      "
                    ></i>
                  </span>
                  <div class="flex-shrink-0 ms-3 text-start">
                    <span
                      class="
                        fw-bold
                        text-decoration-none
                        font-monospace
                        small
                      "
                    >
                      {{ $t("directory_update.verified") }}
                    </span>
                    <p
                      class="
                        small
                        mb-0
                        font-monospace
                        text-muted
                        d-none d-md-flex
                      "
                    >
                      {{
                        $t("directory_update.for_organisations_and_companies")
                      }}
                    </p>
                    <p
                      class="
                        small
                        mb-0
                        font-monospace
                        text-muted text-wrap
                        d-md-none
                      "
                      style="width: 150px"
                    >
                      {{
                        $t("directory_update.for_organisations_and_companies")
                      }}
                    </p>
                  </div>
                  <span
                    class="
                      ms-auto
                      me-4
                      bg-white
                      border
                      rounded-3
                      d-flex
                      align-self-center
                    "
                    style="height: 36px; width: 52px"
                  >
                    <span class="text-primary align-self-center mx-auto"
                      >$299</span
                    >
                  </span>
                </div>
              </div>
            </label>
          </div>
          <div class="form-text">
            {{ $t("directory_update.payment_process_description") }}
          </div>
          <div
            class="btn btn-primary w-100 mt-2"
            role="button"
            @click="createOrder()"
          >
            {{ $t("directory_update.proceed_with_payment") }}
          </div>
          <div
            class="form-text text-center mt-3"
            v-html="
              $t('directory_update.prevent_scams_description') +
              '<br />' +
              $t('directory_update.contact_support_community_project')
            "
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useRoute } from "vue-router";
import useVuelidate from "@vuelidate/core";
import { minLength, maxLength, required } from "@vuelidate/validators";
import { useStore } from "vuex";
export default defineComponent({
  name: "DirectoryUpdate",
  setup() {
    const route = useRoute();
    const store = useStore();
    const state = reactive({
      account: route.query.account,
      labelName: "",
      email: "",
      type: "user_defined",
    });
    const rules = {
      account: { required, minLength: minLength(56), maxLength: maxLength(56) },
      labelName: { required },
      email: {required}
    };
    const v = useVuelidate(rules, state);

    const changeType = (newType) => {
      state.type = newType;
    };
    const apiError = computed(() => store.getters["accounts/getOrder"].error);

    const createOrder = async () => {
      const result = await v.value.$validate();
      if (result) {
        const payload = {
          account: state.account,
          label: { name: state.labelName },
          order_type: state.type,
          email: state.email
        };
        store.dispatch("accounts/createOrder", payload);
      }
    };

    return {
      createOrder,
      changeType,
      route,
      state,
      rules,
      v,
      apiError,
    };
  },
});
</script>

<style scoped>
.card-input-element + .card:hover {
  cursor: pointer;
}

.card-input-element:checked + .card {
  border: 1px solid #0d6efd !important;
  background: rgba(13, 110, 253, 0.1);
}

.card-input-element:checked + .card::after {
  content: "\F633";
  color: #0d6efd;
  font-family: "bootstrap-icons";
  font-size: 24px;
  position: absolute;
  top: 14px;
  right: 10px;
}
</style>
